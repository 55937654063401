<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <Message />
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: var(--v-bgContainer-base); border-radius: 12px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div class="fill-height overflow-y-hidden px-5 d-flex flex-column" column>
            <div
              style="
                flex: none;
                height: 88px;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid rgba(93, 101, 136, 0.5);
              "
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin/cms')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">MENU MANAGEMENT</h3>
                <v-spacer />
                <div>{{ totalData }} menus</div>
              </v-layout>
            </div>
            <div class="pt-5 d-flex flex-column" style="overflow-y: auto; overflow-x: hidden;">
              <div class="mb-5 d-flex">
                <div class="pr-2" style="width: 330px">
                  <v-text-field
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    background-color="#2F324180"
                    dense
                    hide-details
                    label="Search"
                    placeholder="Search"
                    rounded
                    solo
                    @change="filterData"
                  >
                  </v-text-field>
                </div>
                <v-spacer />
                <v-btn color="primary" rounded @click="() => $refs.createDialog.showCreate()">
                  Create menu
                </v-btn>
              </div>
              <div class="pb-2 mt-2" style="flex: 1;overflow-y: auto;">
                <v-simple-table>
                  <v-data-table
                    :headers="headers"
                    :items="menus"
                    :options.sync="options"
                    :server-items-length="totalData"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.image_path`]="{ item }">
                      <div class="mt-2 mb-2">
                        <v-img
                          :src="`${config.backend_pub}${item.image_path}`"
                          class="thumbnail"
                          height="50"
                          width="50"
                        >
                        </v-img>
                      </div>
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                      <div class="d-flex align-center">
                        <div class="ml-3">
                          <div>{{ item.name }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <div class="d-flex align-center justify-center">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="pink" icon v-bind="attrs" @click="showEditServices(item)" v-on="on">
                              <v-icon>mdi-account-wrench</v-icon>
                            </v-btn>
                          </template>
                          <span>Config services</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="pink" icon v-bind="attrs" @click="showEdit(item)" v-on="on">
                              <v-icon>icon-edit_square</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit menu</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="pink" icon v-bind="attrs" @click="showConfirmMenu(item)" v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete menu</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </v-simple-table>
              </div>
              <div class="mt-2 pb-2 d-flex justify-end">
                <v-pagination
                  v-model="page"
                  :length="totalPage"
                  :total-visible="10"
                  circle
                  @input="handChangePage"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </v-layout>
    </v-container>

    <CreateEdit ref="createDialog" />
    <DeleteDialog
      ref="deleteDialog"
      :submit-delete="deleteMenu"
    />
    <ConfigForm
      ref="configServiceDialog"
      title-config="Config Menu Service"
      :list-all-datas="customServices"
      :list-data.sync="menuServices"
      @getData="getMenuServices"
      @updateData="updateMenuServices"
    />
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import { getMenus, deleteMenu, updateDataMenu } from '@/api/menu-api'
import ConvertDate from '@/utils/convertDate'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import CreateEdit from './CreateEditMenu.vue'
import { mapState } from '@/store/ults'
import config from '@/config'
import ConfigForm from '@/views/admin-side/user-management/ConfigForm.vue'

export default {
  name: 'Menu',
  components: {
    ThemeSwitcher,
    DeleteDialog,
    Message,
    CreateEdit,
    ConfigForm
  },

  data() {
    return {
      config: config,
      ConvertDate,
      keyword: undefined,
      loading: false,
      options: {},
      filter: {
        menu: [],
        permission: [],
        role: [],
      },
      menus: [],
      permissions: [],
      settings: {},
      headers: [
        { text: 'Background Image', value: 'image_path', sortable: false },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'code', value: 'code', sortable: false },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Action', value: 'action', align: 'center', sortable: false },
      ],
      menus: [],
      page: 1,
      perPage: 7,
      totalPage: 1,
      roles: [],
      totalData: 0,
      organizations: [],
      menuServices: []
    }
  },
  watch: {
    options: {
      handler() {
        this.getListMenus()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('service', ['services']),
    customServices() {
      return this.services.map(service => {
        return {
          ...service,
          value: service.id,
        }
      })
    }
  },
  async mounted() {
    await this.getListMenus()
    if (this.services.length == 0) {
      await this.$store.dispatch('service/getListServices')
    }
  },
  methods: {
    filterData() {
      this.page = 1
      this.getListMenus()
    },
    async getListMenus() {
      try {
        this.loading = true
        const res = await getMenus({
          roles: this.filter.role,
          permissions: this.filter.permission,
          menus: this.filter.menu,
          per_page: this.perPage,
          page: this.page,
          search: this.keyword,
          sort: this.options.sortBy[0]
            ? !this.options.sortDesc[0]
              ? this.options.sortBy[0]
              : `-${this.options.sortBy[0]}`
            : undefined,
        })
        this.menus = res.data.data.map(menu => {
          return {
            ...menu,
            value: menu.id
          }
        })
        this.totalPage = res.data.last_page
        this.totalData = res.data.total
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async deleteMenu(item) {
      await deleteMenu(item.id)
      await this.getListMenus()
    },
    showConfirmMenu(item) {
      this.$refs.deleteDialog.openDialog(item)
    },
    async handChangePage(page) {
      this.page = page
      this.getListMenus()
    },
    showEdit(data) {
      this.$refs.createDialog.showEdit(data)
    },
    showEditServices(data) {
      this.$refs.configServiceDialog.showCreate(data.id)
    },
    getMenuServices(menuId) {
      const menu = this.menus.find((menu) => menu.id == menuId)
      this.menuServices = menu.services
    },
    async updateMenuServices(data) {
      try {
        this.loading = true
        const menu = this.menus.find((menu) => menu.id == data.uuid)
        const payload = {
          name: menu.name,
          code: menu.code,
          services: data.datas
        }
        await updateDataMenu({ id: data.uuid, payload })
        await this.getListMenus()
      } catch (e) {
        console.warn('Error when update data menu: ', e)
      } finally {
        this.loading = false
      }
    }
  },
}
</script>

<style scoped>
.organ {
  cursor: pointer;
}
.organ :hover {
  color: #3f51b5;
}
</style>
